import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import { AGGREGATE_TYPES, EXACT_GRADE_OR_HIGHER_TYPES, GRADE_TYPES } from "../../../config/const";
import { AggregateTypeTextDef, HeadcountAndRateByGradeTypeTextDef } from "../../../config/text-def";
import { ChartQueryResult } from "../../../dashboard-api";
import {
  AggregateType,
  AggregateTypeRow,
  BaseData,
  ChartSeriesColumnOption,
  ChartSeriesLineOption,
  ExactGradeOrHigherType,
  GradeType,
  HeadcountAndRateType,
  HeadcountType,
  RateType,
} from "../../../types";
import { BaseChartProps } from "../../base-chart";
import { BaseColumnLineChart } from "../base-column-line-chart";

type HeadcountChartSeriesCode = `${AggregateType}_${GradeType}_${ExactGradeOrHigherType}_${HeadcountType}`;
type ExactGradeOrHigherRateChartSeriesCode = `${AggregateType}_${GradeType}_${ExactGradeOrHigherType}_${RateType}`;

export type BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineData = {
  gradeType: GradeType;
  exactGradeOrHigherType: ExactGradeOrHigherType;
  headcountAndRateType: HeadcountAndRateType;
} & BaseData;

export type BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineChartSeriesCode =
  `${AggregateType}_${GradeType}_${ExactGradeOrHigherType}_${HeadcountAndRateType}`;

export class BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineChart extends BaseColumnLineChart<BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineData> {
  private _gradeType: GradeType;
  constructor(props: BaseChartProps, gradeType: GradeType) {
    super(props);
    this._gradeType = gradeType;
  }

  getChartOptions(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineData>
      | ChartQueryResult<BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    inBoard: boolean
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      return {
        ...super.getChartOptions(t, queryResult, displaySwitch, inBoard),
        series: super.getSeries(queryResult, displaySwitch, this._getSeriesCode, this._createSeriesDefs(t)),
      };
    }
    throw new Error("ChartQueryResult must be array.");
  }

  protected _getSeriesCode(
    datum: BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineData
  ): BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineChartSeriesCode {
    return `${datum.aggregateType}_${datum.gradeType}_${datum.exactGradeOrHigherType}_${datum.headcountAndRateType}`;
  }

  protected _createSeriesDefs(t: TFunction): [
    Map<HeadcountChartSeriesCode, ChartSeriesColumnOption>,
    Map<ExactGradeOrHigherRateChartSeriesCode, ChartSeriesLineOption>,
    BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineChartSeriesCode[] // 順序を持ったシリーズコード
  ] {
    const columnMap = new Map<HeadcountChartSeriesCode, ChartSeriesColumnOption>();
    const lineMap = new Map<ExactGradeOrHigherRateChartSeriesCode, ChartSeriesLineOption>();
    const orderedSeriesCodes: BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineChartSeriesCode[] = [];

    AGGREGATE_TYPES.forEach((aggregateType) => {
      GRADE_TYPES.filter((type) => type === this._gradeType).forEach((gradeType) => {
        EXACT_GRADE_OR_HIGHER_TYPES.forEach((exactGradeOrHigherType, i) => {
          const code: ExactGradeOrHigherRateChartSeriesCode = `${aggregateType}_${gradeType}_${exactGradeOrHigherType}_rate`;
          orderedSeriesCodes.push(code);
          lineMap.set(code, {
            name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
              HeadcountAndRateByGradeTypeTextDef.get(`${gradeType}_${exactGradeOrHigherType}_rate`) as string
            )}`,
            color: super.getLineColor(aggregateType, i),
            dashStyle: super.getDashStyle(aggregateType),
          });
        });
      });
      GRADE_TYPES.filter((type) => type === this._gradeType).forEach((gradeType) => {
        EXACT_GRADE_OR_HIGHER_TYPES.filter((type) => type === "exact").forEach((exactGradeOrHigherType) => {
          const code: HeadcountChartSeriesCode = `${aggregateType}_${gradeType}_${exactGradeOrHigherType}_headcount`;
          orderedSeriesCodes.push(code);
          columnMap.set(code, {
            name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
              HeadcountAndRateByGradeTypeTextDef.get(`${gradeType}_${exactGradeOrHigherType}_headcount`) as string
            )}`,
            color: super.getColumnColor(aggregateType, 0),
          });
        });
      });
    });
    return [columnMap, lineMap, orderedSeriesCodes];
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineData>
      | ChartQueryResult<BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      const [columnQueryResult, lineQueryResult] = queryResult;
      return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
        aggregateType,
        rows: GRADE_TYPES.filter((type) => type === this._gradeType).flatMap((gradeType) => [
          ...EXACT_GRADE_OR_HIGHER_TYPES.filter((type) => type === "exact").map((exactGradeOrHigherType) => ({
            header: t(
              HeadcountAndRateByGradeTypeTextDef.get(`${gradeType}_${exactGradeOrHigherType}_headcount`) as string
            ) as string,
            unit: columnQueryResult.unit,
            values: super.getValuesByCondition(
              columnQueryResult,
              (datum) =>
                datum.aggregateType === aggregateType &&
                datum.gradeType === gradeType &&
                datum.exactGradeOrHigherType === exactGradeOrHigherType &&
                datum.headcountAndRateType === "headcount"
            ),
          })),
          ...EXACT_GRADE_OR_HIGHER_TYPES.map((exactGradeOrHigherType) => ({
            header: t(
              HeadcountAndRateByGradeTypeTextDef.get(`${gradeType}_${exactGradeOrHigherType}_rate`) as string
            ) as string,
            unit: lineQueryResult.unit,
            values: super.getValuesByCondition(
              lineQueryResult,
              (datum) =>
                datum.aggregateType === aggregateType &&
                datum.gradeType === gradeType &&
                datum.exactGradeOrHigherType === exactGradeOrHigherType &&
                datum.headcountAndRateType === "rate"
            ),
          })),
        ]),
      }));
    }
    throw new Error("ChartQueryResult must be array.");
  }
}
