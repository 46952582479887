import { IndustryAverageSource } from "../types";

const INDUSTRY_AVERAGE_SOURCE_DICT: {
  [key: string]: IndustryAverageSource;
} = {
  gender_pay_gap: {
    site: "女性の活躍推進企業データベース（https://positive-ryouritsu.mhlw.go.jp/positivedb/opendata/）",
    data: "女性の活躍推進企業データベースオープンデータ（2023年7月24日時点）を加工して作成しています。",
    items: ["従業員規模：500名以上"],
    formula: "",
  },
  female_manager_ratio: {
    site: "女性の活躍推進企業データベース（https://positive-ryouritsu.mhlw.go.jp/positivedb/opendata/）",
    data: "女性の活躍推進企業データベースオープンデータ（2023年7月24日時点）を加工して作成しています。",
    items: ["従業員規模：500名以上"],
    formula: "",
  },
  male_childcare_leave_rate: {
    site: "女性の活躍推進企業データベース（https://positive-ryouritsu.mhlw.go.jp/positivedb/opendata/）",
    data: "女性の活躍推進企業データベースオープンデータ（2023年7月24日時点）を加工して作成しています。",
    items: ["従業員規模：500名以上"],
    formula: "",
  },
  average_annual_salary: {
    site: "政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）",
    data: "「令和４年賃金構造基本統計調査結果」（厚生労働省）を加工して作成",
    items: ["雇用区分：正社員・正職員", "従業員規模：1000名以上"],
    formula: "",
  },
  employee_age_distribution: {
    site: "政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）",
    data: "「令和４年賃金構造基本統計調査結果」（厚生労働省）を加工して作成",
    items: ["雇用区分：正社員・正職員", "従業員規模：1000名以上"],
    formula: "",
  },
  manager_age_distribution: {
    site: "政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）",
    data: "「令和４年賃金構造基本統計調査結果」（厚生労働省）を加工して作成",
    items: ["区分：役職", "従業員規模：1000名以上"],
    formula: "",
  },
  average_age: {
    site: "政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）",
    data: "「令和４年賃金構造基本統計調査結果」（厚生労働省）を加工して作成",
    items: ["雇用区分：全労働者", "雇用区分：正社員・正職員", "従業員規模：1000名以上"],
    formula: "",
  },
  gender_composition: {
    site: "政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）",
    data: "「令和４年賃金構造基本統計調査結果」（厚生労働省）を加工して作成",
    items: ["雇用区分：正社員・正職員", "雇用区分：正社員・正職員以外", "従業員規模：1000名以上"],
    formula: "",
  },
  executive_gender_composition: {
    site: "「女性活躍推進企業データベース」（https://positive-ryouritsu.mhlw.go.jp/positivedb/opendata/） ",
    data: "「女性活躍推進企業データベース」（厚生労働省）2024年2月13日時点を加工して作成",
    items: "従業員規模500名以上",
    formula: "",
  },
  annual_employed_composition: {
    site: "政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）",
    data: "「令和４年雇用動向調査結果」（厚生労働省）を加工して作成",
    items: ["区分：全労働者", "従業員規模：1000名以上"],
    formula: "",
  },
  annual_mid_career_employed_composition: {
    site: "政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）",
    data: "「「令和４年雇用動向調査結果」（厚生労働省）を加工して作成",
    items: ["区分：転職入職者（中途採用）", "従業員規模：1000名以上"],
    formula: "",
  },
  annual_new_graduate_employed_composition: {
    site: "政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）",
    data: "「令和４年賃金構造基本統計調査結果」（厚生労働省）を加工して作成",
    items: ["区分：新規学卒者", "従業員規模：1000名以上"],
    formula: "",
  },
  childcare_leave_rate: {
    site: "「女性活躍推進企業データベース」（https://positive-ryouritsu.mhlw.go.jp/positivedb/opendata/） ",
    data: "「女性活躍推進企業データベース」（厚生労働省）2024年2月13日時点を加工して作成",
    items: "従業員規模500名以上",
    formula: "",
  },
  monthly_hours_over_scheduled_working_hours: {
    site: "政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）",
    data: "「令和４年賃金構造基本統計調査結果」（厚生労働省）を加工して作成",
    items: ["雇用区分：正社員・正職員", "従業員規模：1000名以上"],
    formula: "",
  },
  average_annual_paid_leave_consumed: {
    site: "政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）",
    data: "「令和５年就労条件総合調査」（厚生労働省）を加工して作成",
    items: ["対象者：常用労働者", "従業員規模：1000名以上"],
    formula: "",
  },
  foreign_employee_rate: {
    site: "政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）",
    data: "「外国人雇用状況」（厚生労働省）、「労働力調査」（総務省）を加工して作成",
    items: "",
    formula: "外国人労働者数÷就業者数",
  },
  average_service_year: {
    site: "政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）",
    data: "「令和４年賃金構造基本統計調査結果」（厚生労働省）を加工して作成",
    items: ["雇用区分：正社員・正職員", "従業員規模：1000名以上"],
    formula: "",
  },
  gender_average_service_year_gap: {
    site: "政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）",
    data: "「令和４年賃金構造基本統計調査結果」（厚生労働省）を加工して作成",
    items: ["雇用区分：正社員・正職員", "従業員規模：1000名以上"],
    formula: "",
  },
};

export const CHART_INDICATOR_INDUSTRY_AVERAGE_RELATIONS = {
  gender_pay_gap: {
    gender_pay_gap: INDUSTRY_AVERAGE_SOURCE_DICT["gender_pay_gap"],
  },
  female_manager_ratio: {
    female_manager_ratio: INDUSTRY_AVERAGE_SOURCE_DICT["female_manager_ratio"],
  },
  male_childcare_leave_rate: {
    male_childcare_leave_rate: INDUSTRY_AVERAGE_SOURCE_DICT["male_childcare_leave_rate"],
  },
  average_annual_salary_of_regular_employee_by_gender: {
    average_annual_salary_of_regular_employee_by_gender: INDUSTRY_AVERAGE_SOURCE_DICT["average_annual_salary"],
  },
  age_distribution_of_regular_employee_by_gender: {
    age_distribution_of_regular_employee_by_gender: INDUSTRY_AVERAGE_SOURCE_DICT["employee_age_distribution"],
  },
  age_distribution_of_manager_by_gender: {
    age_distribution_of_manager_by_gender: INDUSTRY_AVERAGE_SOURCE_DICT["manager_age_distribution"],
  },
  average_age_of_all_employee_by_gender: {
    average_age_of_all_employee_by_gender: INDUSTRY_AVERAGE_SOURCE_DICT["average_age"],
  },
  average_age_of_regular_employee_by_gender: {
    average_age_of_regular_employee_by_gender: INDUSTRY_AVERAGE_SOURCE_DICT["average_age"],
  },
  gender_composition_of_all_employee: {
    gender_composition_of_all_employee_ratio: INDUSTRY_AVERAGE_SOURCE_DICT["gender_composition"],
  },
  gender_composition_of_regular_employee: {
    gender_composition_of_regular_employee_ratio: INDUSTRY_AVERAGE_SOURCE_DICT["gender_composition"],
  },
  gender_composition_of_non_regular_employee: {
    gender_composition_of_non_regular_employee_ratio: INDUSTRY_AVERAGE_SOURCE_DICT["gender_composition"],
  },
  executive_gender_composition: {
    executive_gender_composition_ratio: INDUSTRY_AVERAGE_SOURCE_DICT["executive_gender_composition"],
    executive_gender_composition_headcount: INDUSTRY_AVERAGE_SOURCE_DICT["executive_gender_composition"],
  },
  annual_employed_composition_of_all_employee_by_gender: {
    annual_employed_composition_of_all_employee_by_gender_ratio:
      INDUSTRY_AVERAGE_SOURCE_DICT["annual_employed_composition"],
  },
  annual_mid_career_employed_composition_of_regular_employee_by_gender: {
    annual_mid_career_employed_composition_of_regular_employee_by_gender_ratio:
      INDUSTRY_AVERAGE_SOURCE_DICT["annual_mid_career_employed_composition"],
  },
  annual_new_graduate_employed_composition_of_regular_employee_by_gender: {
    annual_new_graduate_employed_composition_of_regular_employee_by_gender_ratio:
      INDUSTRY_AVERAGE_SOURCE_DICT["annual_new_graduate_employed_composition"],
  },
  childcare_leave_rate_of_all_employee_by_gender: {
    childcare_leave_rate_of_all_employee_by_gender: INDUSTRY_AVERAGE_SOURCE_DICT["childcare_leave_rate"],
  },
  regular_employee_average_monthly_hours_over_scheduled_working_hours_of_all_employee: {
    regular_employee_average_monthly_hours_over_scheduled_working_hours_of_all_employee:
      INDUSTRY_AVERAGE_SOURCE_DICT["monthly_hours_over_scheduled_working_hours"],
  },
  average_annual_paid_leave_consumed_days_and_rate_of_regular_employee: {
    average_annual_paid_leave_consumed_days_and_rate_of_regular_employee_days:
      INDUSTRY_AVERAGE_SOURCE_DICT["average_annual_paid_leave_consumed"],
  },
  foreign_employee_headcount_and_rate_of_all_employee: {
    foreign_employee_headcount_and_rate_of_all_employee_rate: INDUSTRY_AVERAGE_SOURCE_DICT["foreign_employee_rate"],
  },
  average_service_year_of_regular_employee_by_gender: {
    average_service_year_of_regular_employee_by_gender: INDUSTRY_AVERAGE_SOURCE_DICT["average_service_year"],
  },
  gender_average_service_year_gap_of_regular_employee: {
    gender_average_service_year_gap_of_regular_employee:
      INDUSTRY_AVERAGE_SOURCE_DICT["gender_average_service_year_gap"],
  },
};
