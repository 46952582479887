import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../components/dashboard-chart-display-switch";
import { ALL_INDUSTRY_AVERAGE_COLORS, INDUSTRY_AVERAGE_COLORS, OUR_COMPANY_COLORS } from "../../config/const";
import { ChartQueryResult } from "../../dashboard-api";
import { AggregateType, AggregateTypeRow, BaseData, ChartSeriesColumnOption } from "../../types";
import { BaseChart, BaseChartProps } from "../base-chart";

// テンプレートリテラルを利用してBaseChartSeriesとAggregateTypeがイコールにならないようにしている
export type BaseChartSeriesCode = `${AggregateType}`;

type TmpResult<TChartSeriesCode extends string> = { code: TChartSeriesCode; data: (number | null)[] };

export abstract class BaseStackedColumnChart<TData extends BaseData> extends BaseChart<TData> {
  constructor(props: BaseChartProps) {
    super(props);
  }

  getChartOptions(
    t: TFunction,
    queryResult: ChartQueryResult<TData> | ChartQueryResult<TData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    inBoard: boolean
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }
    return {
      ...super.getChartOptions(t, queryResult, displaySwitch, inBoard),
      plotOptions: {
        series: {
          dataLabels: { enabled: displaySwitch.showDataLabel },
        },
        column: {
          stacking: "normal",
          zIndex: 1,
        },
      },
      // series: Baseで共通のものができた際に実装
    };
  }

  protected getColor(aggregateType: AggregateType, columnNum: number, i = 0) {
    switch (aggregateType) {
      case "our_company":
        return OUR_COMPANY_COLORS.slice(0, columnNum).toReversed()[i];
      case "industry_average":
        return INDUSTRY_AVERAGE_COLORS.slice(0, columnNum).toReversed()[i];
      case "all_industry_average":
        return ALL_INDUSTRY_AVERAGE_COLORS.slice(0, columnNum).toReversed()[i];
    }
  }

  protected getSeries<TData extends BaseData, TChartSeriesCode extends string>(
    queryResult: ChartQueryResult<TData> | ChartQueryResult<TData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    getChartSeriesCode: (datum: TData) => TChartSeriesCode,
    chartSeriesDef: Map<TChartSeriesCode, ChartSeriesColumnOption>,
    filterByDisplayValuesFunc?: (data: TData[], displayValues: string[]) => TData[]
  ): Highcharts.SeriesColumnOptions[] {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }

    const tmpResults: TmpResult<TChartSeriesCode>[] = [];
    const orderedSeriesCodes = Array.from(chartSeriesDef.keys());

    queryResult.datasets.forEach((dataset) => {
      // dataは空配列などseries分のデータがない場合があるため、seriesでループ
      orderedSeriesCodes.forEach((code) => {
        // 属すべきseriesを特定
        let tmpResult = tmpResults.find((t) => t.code === code);
        if (!tmpResult) {
          // 存在しないseriesなら新規作成
          tmpResult = { code, data: [] };
          tmpResults.push(tmpResult);
        }
        const targetData = filterByDisplayValuesFunc
          ? filterByDisplayValuesFunc(dataset.data, displaySwitch.selectedDisplayValues)
          : dataset.data;
        tmpResult.data.push(targetData.find((datum) => getChartSeriesCode(datum) === code)?.value ?? null);
      });
    });

    // TODO: もっといい方法ないかな
    const getStack = (code: TChartSeriesCode): AggregateType => {
      if (code.startsWith("our_company")) return "our_company";
      if (code.startsWith("industry_average")) return "industry_average";
      if (code.startsWith("all_industry_average")) return "all_industry_average";
      throw new Error("error");
    };

    return (
      this._filterDisplayData(tmpResults, displaySwitch)
        // 凡例の表示順を揃えるため、定義した順序に並び替え
        .sort((a, b) => orderedSeriesCodes.indexOf(a.code) - orderedSeriesCodes.indexOf(b.code))
        .map(({ code, data }) => ({
          type: "column",
          name: chartSeriesDef.get(code)?.name,
          color: chartSeriesDef.get(code)?.color,
          data: data.every((d) => d === null) ? [] : data, // 全ての値がnullの場合はlang.noDataプロパティを利用できるように空配列を渡す
          yAxis: 0,
          stack: getStack(code),
          showInLegend: code.includes("our_company") || data.some((d) => d !== null),
        }))
    );
  }

  private _filterDisplayData<TChartSeriesCode extends string>(
    tmpResults: TmpResult<TChartSeriesCode>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): TmpResult<TChartSeriesCode>[] {
    const results = tmpResults.filter((tmpResult) => {
      if (tmpResult.code.startsWith("industry_average")) {
        // aggregateTypeがindustry_averageの場合、displaySwitch.showIndustryAverageがfalseであれば表示対象から除外
        return displaySwitch.showIndustryAverage;
      }
      if (tmpResult.code.startsWith("all_industry_average")) {
        // aggregateTypeがall_industry_averageの場合、displaySwitch.showAllIndustryAverageがfalseであれば表示対象から除外
        return displaySwitch.showAllIndustryAverage;
      }
      return true;
    });
    return results;
  }

  // Baseで共通のものができた際にabstractを外して実装
  abstract getAggregateTypeRows(
    t: TFunction,
    queryResult: ChartQueryResult<TData> | ChartQueryResult<TData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[];
}
