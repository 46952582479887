import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

type DialogHeaderProps = {
  onClose: () => void;
  children: React.ReactNode;
  dialogType?: string;
};

export const DialogHeader = ({ onClose, children, dialogType }: DialogHeaderProps) => {
  return (
    <DialogTitle
      sx={{
        bgcolor: dialogType === "delete" ? "normal" : "primary.main",
        color: dialogType === "delete" ? "common.black" : "common.white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {children}
      <IconButton onClick={onClose} sx={{ color: dialogType === "delete" ? "common.black" : "common.white" }}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};
