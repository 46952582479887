import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../components/dashboard-chart-display-switch";
import {
  AGGREGATE_TYPES,
  ALL_INDUSTRY_AVERAGE_COLORS,
  INDUSTRY_AVERAGE_COLORS,
  OUR_COMPANY_COLORS,
} from "../../config/const";
import { AggregateTypeTextDef } from "../../config/text-def";
import { ChartQueryResult } from "../../dashboard-api";
import { AggregateType, AggregateTypeRow, BaseData, ChartSeriesLineOption } from "../../types";
import { BaseChart, BaseChartProps } from "../base-chart";

// テンプレートリテラルを利用してBaseChartSeriesとAggregateTypeがイコールにならないようにしている
export type BaseChartSeriesCode = `${AggregateType}`;

type TmpResult<TChartSeriesCode extends string> = { code: TChartSeriesCode; data: (number | null)[] };

export abstract class BaseLineChart<TData extends BaseData> extends BaseChart<TData> {
  constructor(props: BaseChartProps) {
    super(props);
  }

  getChartOptions(
    t: TFunction,
    queryResult: ChartQueryResult<TData> | ChartQueryResult<TData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    inBoard: boolean
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }
    return {
      ...super.getChartOptions(t, queryResult, displaySwitch, inBoard),
      series: this.getSeries(queryResult, displaySwitch, this._getBaseSeriesCode, this._createBaseSeriesDef(t)),
    };
  }

  private _getBaseSeriesCode(datum: BaseData): BaseChartSeriesCode {
    return datum.aggregateType;
  }

  private _createBaseSeriesDef(t: TFunction): Map<BaseChartSeriesCode, ChartSeriesLineOption> {
    const results = new Map<BaseChartSeriesCode, ChartSeriesLineOption>();
    AGGREGATE_TYPES.forEach((aggregateType) => {
      results.set(aggregateType, {
        name: t(AggregateTypeTextDef.get(aggregateType) as string),
        color: this.getColor(aggregateType),
        dashStyle: this.getDashStyle(aggregateType),
      });
    });
    return results;
  }

  protected getColor(aggregateType: AggregateType, i = 0) {
    switch (aggregateType) {
      case "our_company":
        return OUR_COMPANY_COLORS[i];
      case "industry_average":
        return INDUSTRY_AVERAGE_COLORS[i];
      case "all_industry_average":
        return ALL_INDUSTRY_AVERAGE_COLORS[i];
    }
  }

  protected getDashStyle(aggregateType: AggregateType): Highcharts.DashStyleValue {
    switch (aggregateType) {
      case "our_company":
        return "Solid";
      case "industry_average":
        return "Dot";
      case "all_industry_average":
        return "LongDash";
    }
  }

  protected getSeries<TData extends BaseData, TChartSeriesCode extends string>(
    queryResult: ChartQueryResult<TData> | ChartQueryResult<TData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    getChartSeriesCode: (datum: TData) => TChartSeriesCode,
    chartSeriesDef: Map<TChartSeriesCode, ChartSeriesLineOption>,
    filterByDisplayValuesFunc?: (data: TData[], displayValues: string[]) => TData[]
  ): Highcharts.SeriesLineOptions[] {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }

    const tmpResults: TmpResult<TChartSeriesCode>[] = [];
    const orderedSeriesCodes = Array.from(chartSeriesDef.keys());

    queryResult.datasets.forEach((dataset) => {
      // dataは空配列などseries分のデータがない場合があるため、seriesでループ
      orderedSeriesCodes.forEach((code) => {
        // 属すべきseriesを特定
        let tmpResult = tmpResults.find((t) => t.code === code);
        if (!tmpResult) {
          // 存在しないseriesなら新規作成
          tmpResult = { code, data: [] };
          tmpResults.push(tmpResult);
        }
        const targetData = filterByDisplayValuesFunc
          ? filterByDisplayValuesFunc(dataset.data, displaySwitch.selectedDisplayValues)
          : dataset.data;
        tmpResult.data.push(targetData.find((datum) => getChartSeriesCode(datum) === code)?.value ?? null);
      });
    });

    // seriesをループしてHighchartsのseriesに変換
    return (
      this._filterDisplayData(tmpResults, displaySwitch)
        // 凡例の表示順を揃えるため、定義した順序に並び替え
        .sort((a, b) => orderedSeriesCodes.indexOf(a.code) - orderedSeriesCodes.indexOf(b.code))
        .map(({ code, data }) => ({
          type: "line",
          marker: { symbol: "circle" },
          name: chartSeriesDef.get(code)?.name,
          color: chartSeriesDef.get(code)?.color,
          dashStyle: chartSeriesDef.get(code)?.dashStyle,
          data: data.every((d) => d === null) ? [] : data,
          showInLegend: code.includes("our_company") || data.some((d) => d !== null),
        }))
    );
  }

  private _filterDisplayData<TChartSeriesCode extends string>(
    tmpResults: TmpResult<TChartSeriesCode>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): TmpResult<TChartSeriesCode>[] {
    const results = tmpResults.filter((tmpResult) => {
      if (tmpResult.code.startsWith("industry_average")) {
        // aggregateTypeがindustry_averageの場合、displaySwitch.showIndustryAverageがfalseであれば表示対象から除外
        return displaySwitch.showIndustryAverage;
      }
      if (tmpResult.code.startsWith("all_industry_average")) {
        // aggregateTypeがall_industry_averageの場合、displaySwitch.showAllIndustryAverageがfalseであれば表示対象から除外
        return displaySwitch.showAllIndustryAverage;
      }
      return true;
    });
    return results;
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult: ChartQueryResult<BaseData> | ChartQueryResult<BaseData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }
    return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
      aggregateType,
      rows: [
        {
          unit: queryResult.unit,
          values: this.getValuesByCondition(queryResult, (datum) => datum.aggregateType === aggregateType),
        },
      ],
    }));
  }
}
