import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import { DATA_GRID_FOOTER_HEIGHT, DATA_GRID_NO_ROWS_HEIGHT } from "../../../config/style";
import { Chart } from "../../../types";
import { dashboardApi, DashboardIndividualListGetRequest } from "../dashboard-api";

type DashboardIndividualListTableProps = {
  individualListFilter: DashboardIndividualListGetRequest;
  chart: Chart;
};

const INDIVIDUAL_LIST_DIALOG_PAGE_SIZE = 10;

export const DashboardIndividualListTable = ({ individualListFilter, chart }: DashboardIndividualListTableProps) => {
  const { t } = useTranslation();
  const useIndividualListQuery = dashboardApi.useIndividualList(chart, individualListFilter);
  const columns: GridColDef[] = [
    {
      field: "employeeNumber",
      headerName: t("individual-list.employee-number") as string,
      flex: 1,
    },
    {
      field: "name",
      headerName: t("individual-list.employee-name") as string,
      flex: 1,
    },
  ];

  const getRowsWithId = () => {
    if (useIndividualListQuery.data && !useIndividualListQuery.isLoading) {
      return useIndividualListQuery.data.map((row, index) => ({
        ...row,
        id: index,
      }));
    }
    return [];
  };

  return (
    <Grid item xs={12} mt={1}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-footerContainer": {
            height: DATA_GRID_FOOTER_HEIGHT,
            minHeight: DATA_GRID_FOOTER_HEIGHT,
          },
          "& .MuiDataGrid-row:nth-of-type(1) .MuiDataGrid-cell": {
            borderTop: "none",
          },
          minHeight: DATA_GRID_NO_ROWS_HEIGHT,
        }}
        columns={columns}
        columnHeaderHeight={35}
        rows={getRowsWithId()}
        rowSelection={false}
        rowHeight={35}
        disableRowSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        disableColumnSorting
        autoHeight
        initialState={{
          pagination: { paginationModel: { pageSize: INDIVIDUAL_LIST_DIALOG_PAGE_SIZE } },
        }}
        loading={useIndividualListQuery.isLoading}
        slots={{
          noRowsOverlay: () => (
            <Box height={DATA_GRID_NO_ROWS_HEIGHT} display="flex" justifyContent="center" alignItems="center">
              <Typography color={(theme) => theme.palette.grey[500]}>{t("no-data")}</Typography>
            </Box>
          ),
        }}
      />
    </Grid>
  );
};
