import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { unstable_usePrompt } from "react-router-dom";
import { SEPARATOR } from "../../../config/const";
import { PLACEHOLDER_OPACITY } from "../../../config/style";
import { ChartTextDef, GroupTextDef } from "../../../config/text-def";
import { Chart } from "../../../types";
import { GROUP_CHARTS_DICT } from "../config/const";
import { DashboardStore } from "../stores/dashboard-store";
import { ChartGroup } from "../types";

const SELECT_ITEM_HEIGHT = 480;
const SEPARATED_CHART_INDEX = 1;

type DashboardChartSelectProps = {
  groups: ChartGroup[];
  onUpdateGroups: (groups: ChartGroup[]) => void;
};

export const DashboardChartSelect = ({ groups, onUpdateGroups }: DashboardChartSelectProps) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<ChartGroup[]>([]);
  const { formDirty, setFormDirty } = DashboardStore.useContainer();

  useEffect(() => {
    setSelectedOptions(groups);
  }, [groups]);

  // ブラウザバックするときに警告を出す
  unstable_usePrompt({
    when: formDirty,
    message: t("confirmation.dialog.message"),
  });

  const onChange = (event: SelectChangeEvent<string[]>) => {
    const newGroups: ChartGroup[] = [];

    (event.target.value as string[]).forEach((item) => {
      const [group, chart] = item.split(SEPARATOR);
      const existingGroup = newGroups.find((r) => r.group === group);

      if (existingGroup) {
        existingGroup.charts.push(chart as Chart);
      } else {
        newGroups.push({
          group: group,
          charts: [chart as Chart],
        });
      }
    });

    setSelectedOptions(newGroups);
    onUpdateGroups(newGroups);
    setFormDirty(true);
  };

  return (
    <Box justifyContent={"center"} alignItems={"center"} display={"flex"} mb={1}>
      <Typography component="span" sx={{ width: 40, mr: 1 }}>
        {t("indicator")}
      </Typography>
      <FormControl fullWidth>
        <Select
          sx={{ bgcolor: "common.white" }}
          multiple
          displayEmpty
          value={selectedOptions.flatMap((option) =>
            option.charts.map((chart) => `${option.group}${SEPARATOR}${chart}`)
          )}
          onChange={onChange}
          renderValue={(selectedValues) => {
            if (!selectedValues.length) {
              return (
                <Typography component="span" sx={{ opacity: PLACEHOLDER_OPACITY }}>
                  {t("placeholder.select-chart") as string}
                </Typography>
              );
            }
            return (
              <Typography component="span">
                {selectedValues
                  .map((selectedValue) =>
                    t(ChartTextDef.get(selectedValue.split(SEPARATOR)[SEPARATED_CHART_INDEX] as Chart) as string)
                  )
                  .join("、")}
              </Typography>
            );
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: SELECT_ITEM_HEIGHT,
              },
            },
          }}
        >
          {Object.entries(GROUP_CHARTS_DICT).map(([group, charts], groupIndex) => [
            <ListSubheader
              disableSticky
              key={group}
              sx={{ height: 1, lineHeight: 1, mt: groupIndex === 0 ? 0.5 : 1.5, mb: 0.5 }}
              component="div"
            >
              {t(GroupTextDef.get(group) as string)}
            </ListSubheader>,
            charts.map((chart) => {
              return (
                <MenuItem
                  key={`${group}${SEPARATOR}${chart}`}
                  value={`${group}${SEPARATOR}${chart}`}
                  sx={{ minHeight: 24 }}
                >
                  <Checkbox
                    checked={selectedOptions.flatMap((group) => group.charts).includes(chart as Chart)}
                    sx={{ p: 0, mx: 1 }}
                  />
                  <ListItemText primary={t(ChartTextDef.get(chart as Chart) as string)} />
                </MenuItem>
              );
            }),
          ])}
        </Select>
      </FormControl>
    </Box>
  );
};
