import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import { AGGREGATE_TYPES, EMPLOYEE_TYPES } from "../../../config/const";
import { AggregateTypeTextDef, EmployeeTypeTextDef } from "../../../config/text-def";
import { ChartQueryResult } from "../../../dashboard-api";
import { AggregateType, AggregateTypeRow, BaseData, ChartSeriesLineOption, EmployeeType } from "../../../types";
import { BaseChartProps } from "../../base-chart";
import { BaseLineChart } from "../base-line-chart";

export type BaseEmployeeTypeLineData = {
  employeeType: EmployeeType;
} & BaseData;

export type BaseEmployeeTypeLineChartSeriesCode = `${AggregateType}_${EmployeeType}`;

export class BaseEmployeeTypeLineChart extends BaseLineChart<BaseData> {
  constructor(props: BaseChartProps) {
    super(props);
    this.individualListLegendItems = [...EMPLOYEE_TYPES];
  }

  getChartOptions(
    t: TFunction,
    queryResult: ChartQueryResult<BaseEmployeeTypeLineData> | ChartQueryResult<BaseEmployeeTypeLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    inBoard: boolean
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }

    return {
      ...super.getChartOptions(t, queryResult, displaySwitch, inBoard),
      series: super.getSeries(queryResult, displaySwitch, this._getSeriesCode, this._createSeriesDefs(t)),
    };
  }

  protected _getSeriesCode(datum: BaseEmployeeTypeLineData): BaseEmployeeTypeLineChartSeriesCode {
    return `${datum.aggregateType}_${datum.employeeType}`;
  }

  protected _createSeriesDefs(t: TFunction): Map<BaseEmployeeTypeLineChartSeriesCode, ChartSeriesLineOption> {
    const lineMap = new Map<BaseEmployeeTypeLineChartSeriesCode, ChartSeriesLineOption>();

    AGGREGATE_TYPES.forEach((aggregateType) => {
      EMPLOYEE_TYPES.forEach((employeeType, i) => {
        const code: BaseEmployeeTypeLineChartSeriesCode = `${aggregateType}_${employeeType}`;
        lineMap.set(code, {
          name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
            EmployeeTypeTextDef.get(employeeType) as string
          )}`,
          color: super.getColor(aggregateType, i),
          dashStyle: super.getDashStyle(aggregateType),
        });
      });
    });
    return lineMap;
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult: ChartQueryResult<BaseEmployeeTypeLineData> | ChartQueryResult<BaseEmployeeTypeLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }

    return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
      aggregateType,
      rows: EMPLOYEE_TYPES.map((employeeType) => {
        return {
          header: t(EmployeeTypeTextDef.get(employeeType) as string) as string,
          unit: queryResult.unit,
          values: super.getValuesByCondition(
            queryResult,
            (datum) => datum.aggregateType === aggregateType && datum.employeeType === employeeType
          ),
        };
      }),
    }));
  }
}
