import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { TenantLayout } from "../../../../../components/layout/tenant-layout";
import { Loading } from "../../../../../components/loading/loading";
import { LoadingModal } from "../../../../../components/loading/loading-modal";
import { MyTooltip } from "../../../../../components/my-tooltip";
import { PRESET_BOARD_IDS } from "../../../../../config/const";
import { DISABLED_OPACITY } from "../../../../../config/style";
import { GroupTextDef } from "../../../../../config/text-def";
import { AuthStore } from "../../../../../stores/auth-store";
import { Chart } from "../../../../../types";
import { BaseChartProps } from "../../../classes/base-chart";
import { AgeDistributionByGenderChart } from "../../../classes/column/binary-gender-type/age-distribution-by-gender/age-distribution-by-gender-chart";
import { AverageChildcareLeaveTakenDaysByGenderChart } from "../../../classes/column/binary-gender-type/average-childcare-leave-taken-days-by-gender/average-childcare-leave-taken-days-by-gender-chart";
import { BaseBinaryGenderTypeColumnData } from "../../../classes/column/binary-gender-type/base-binary-gender-type-column-chart";
import { DisabledEmploymentShortageHeadcountChart } from "../../../classes/column/disabled-employment-shortage-headcount/disabled-employment-shortage-headcount-chart";
import { AverageServiceYearByGenderChart } from "../../../classes/column/gender-type/average-service-year-by-gender/average-service-year-by-gender-chart";
import { BaseGenderTypeColumnData } from "../../../classes/column/gender-type/base-gender-type-column-chart";
import { RegularEmployeeAverageAnnualHoursOverScheduledWorkingHoursChart } from "../../../classes/column/regular-employee-average-annual-hours-over-scheduled-working-hours/regular-employee-average-annual-hours-over-scheduled-working-hours-chart";
import { RegularEmployeeAverageMonthlyHoursOverScheduledWorkingHoursChart } from "../../../classes/column/regular-employee-average-monthly-hours-over-scheduled-working-hours/regular-employee-average-monthly-hours-over-scheduled-working-hours-chart";
import { SpanOfControlChart } from "../../../classes/column/span-of-control/span-of-control-chart";
import { AverageAnnualPaidLeaveConsumedDaysAndRateChart } from "../../../classes/column-line/days-and-rate/average-annual-paid-leave-consumed-days-and-rate/average-annual-paid-leave-consumed-days-and-rate-chart";
import { BaseDaysAndRateTypeColumnLineData } from "../../../classes/column-line/days-and-rate/base-days-and-rate-type-column-line-chart";
import { BaseHeadcountAndRateTypeColumnLineData } from "../../../classes/column-line/headcount-and-rate-type/base-headcount-and-rate-type-column-line-chart";
import { ChildcarePurposeShiftedStartTimeWorkHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/childcare-purpose-shifted-start-time-work-headcount-and-rate/childcare-purpose-shifted-start-time-work-headcount-and-rate-chart";
import { ChildcarePurposeShortTimeWorkHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/childcare-purpose-short-time-work-headcount-and-rate/childcare-purpose-short-time-work-headcount-and-rate-chart";
import { ForeignEmployeeHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/foreign-employee-headcount-and-rate/foreign-employee-headcount-and-rate-chart";
import { ForeignExecutiveHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/foreign-executive-headcount-and-rate/foreign-executive-headcount-and-rate-chart";
import { ForeignNewGraduateEmployedHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/foreign-new-graduate-employed-headcount-and-rate/foreign-new-graduate-employed-headcount-and-rate-chart";
import { MidCareerEmployedExecutiveHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/mid-career-employed-executive-headcount-and-rate/mid-career-employed-executive-headcount-and-rate-chart";
import { MidCareerEmployedHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/mid-career-employed-headcount-and-rate/mid-career-employed-headcount-and-rate-chart";
import { BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineData } from "../../../classes/column-line/headcount-and-rate-type-grade-type-exact-grade-or-higher-type/base-headcount-and-rate-type-grade-type-exact-grade-or-higher-type-column-line-chart";
import { FemaleHeadcountAndRateOfDivisionManagerChart } from "../../../classes/column-line/headcount-and-rate-type-grade-type-exact-grade-or-higher-type/female-headcount-and-rate-of-division-manager/female-headcount-and-rate-of-division-manager-chart";
import { FemaleHeadcountAndRateOfSectionManagerChart } from "../../../classes/column-line/headcount-and-rate-type-grade-type-exact-grade-or-higher-type/female-headcount-and-rate-of-section-manager/female-headcount-and-rate-of-section-manager-chart";
import { FemaleHeadcountAndRateOfTeamLeaderChart } from "../../../classes/column-line/headcount-and-rate-type-grade-type-exact-grade-or-higher-type/female-headcount-and-rate-of-team-leader/female-headcount-and-rate-of-team-leader-chart";
import { BaseHeadcountAndRatioTypeColumnLineData } from "../../../classes/column-line/headcount-and-ratio-type/base-headcount-and-ratio-type-column-line-chart";
import { MaleChildcareLeaveTakenDayDistributionChart } from "../../../classes/column-line/headcount-and-ratio-type/male-childcare-leave-taken-day-distribution/male-childcare-leave-taken-day-distribution-chart";
import { BaseHeadcountAndSpanOfControlTypeColumnLineData } from "../../../classes/column-line/headcount-and-span-of-control-type/base-headcount-and-span-of-control-type-column-line-chart";
import { ProvinceDirectSpanOfControlChart } from "../../../classes/column-line/headcount-and-span-of-control-type/province-direct-span-of-control/province-direct-span-of-control-chart";
import { BaseEmployeeTypeLineData } from "../../../classes/line/employee-type/base-employee-type-line-chart";
import { GenderPayGapChart } from "../../../classes/line/employee-type/gender-pay-gap/gender-pay-gap-chart";
import { MaleChildcareLeaveRateByEmployeeTypeChart } from "../../../classes/line/employee-type/male-childcare-leave-rate-by-employee-type/male-childcare-leave-rate-by-employee-type-chart";
import { FemaleManagerRatioChart } from "../../../classes/line/female-manager-ratio/female-manager-ratio-chart";
import { GenderAverageServiceYearGapChart } from "../../../classes/line/gender-average-service-year-gap/gender-average-service-year-gap-chart";
import { AnnualEmployedXYearRetentionRateByGenderChart } from "../../../classes/line/gender-type/annual-employed-x-year-retention-rate-by-gender/annual-employed-x-year-retention-rate-by-gender-chart";
import { AverageAgeByGenderChart } from "../../../classes/line/gender-type/average-age-by-gender/average-age-by-gender-chart";
import { AverageAnnualBonusByGenderChart } from "../../../classes/line/gender-type/average-annual-bonus-by-gender/average-annual-bonus-by-gender-chart";
import { AverageAnnualSalaryByGenderChart } from "../../../classes/line/gender-type/average-annual-salary-by-gender/average-annual-salary-by-gender-chart";
import { BaseGenderTypeLineData } from "../../../classes/line/gender-type/base-gender-type-line-chart";
import { ChildcareLeaveRateByGenderChart } from "../../../classes/line/gender-type/childcare-leave-rate-by-gender/childcare-leave-rate-by-gender-chart";
import { RegularFullTimeEmployeeAverageAnnualHoursActualWorkingHoursByGenderChart } from "../../../classes/line/gender-type/regular-full-time-employee-average-annual-hours-actual-working-hours-by-gender/regular-full-time-employee-average-annual-hours-actual-working-hours-by-gender-chart";
import { RegularFullTimeEmployeeAverageMonthlyHoursActualWorkingHoursByGenderChart } from "../../../classes/line/gender-type/regular-full-time-employee-average-monthly-hours-actual-working-hours-by-gender/regular-full-time-employee-average-monthly-hours-actual-working-hours-by-gender-chart";
import { AverageAnnualBonusByGradeTypeChart } from "../../../classes/line/grade-type/average-annual-bonus-by-grade-type/average-annual-bonus-by-grade-type-chart";
import { BaseGradeTypeLineData } from "../../../classes/line/grade-type/base-grade-type-line-chart";
import { MaleChildcareLeaveOrChildcarePurposeLeaveRateChart } from "../../../classes/line/male-childcare-leave-or-childcare-purpose-leave-rate/male-childcare-leave-or-childcare-purpose-leave-rate-chart";
import { MaleChildcareLeaveRateChart } from "../../../classes/line/male-childcare-leave-rate/male-childcare-leave-rate-chart";
import { BaseManagementTypeLineData } from "../../../classes/line/management-type/base-management-type-line-chart";
import { SalaryIncreaseRateByManagementTypeChart } from "../../../classes/line/management-type/salary-increase-rate-by-management-type/salary-increase-rate-by-management-type-chart";
import { ManagerRateChart } from "../../../classes/line/manager-rate/manager-rate-chart";
import { BaseDisciplineTypeStackedColumnData } from "../../../classes/stacked-column/discipline-type/base-discipline-type-stacked-column-chart";
import { DisciplineCountByDisciplineTypeChart } from "../../../classes/stacked-column/discipline-type/discipline-count-by-discipline-type/discipline-count-by-discipline-type-chart";
import { BaseEmployeeTypeStackedColumnData } from "../../../classes/stacked-column/employee-type/base-employee-type-stacked-column-chart";
import { FullTimeEmployeeHeadcountByEmployeeTypeChart } from "../../../classes/stacked-column/employee-type/full-time-employee-headcount-by-employee-type/full-time-employee-headcount-by-employee-type-chart";
import { BaseGenderTypeStackedColumnData } from "../../../classes/stacked-column/gender-type/base-gender-type-stacked-column-chart";
import { FamilycareLeaveHeadcountByGenderChart } from "../../../classes/stacked-column/gender-type/familycare_leave_headcount_by_gender/familycare-leave-headcount-by-gender-chart";
import { ForeignEmployeeHeadcountByGenderChart } from "../../../classes/stacked-column/gender-type/foreign-employee-headcount-by-gender/foreign-employee-headcount-by-gender-chart";
import { FullTimeEmployeeHeadcountByGenderChart } from "../../../classes/stacked-column/gender-type/full-time-employee-headcount-by-gender/full-time-employee-headcount-by-gender-chart";
import { BaseNationalityTypeStackedColumnData } from "../../../classes/stacked-column/nationality-type/base-nationality-type-stacked-column-chart";
import { ForeignEmployeeHeadcountByNationalityChart } from "../../../classes/stacked-column/nationality-type/foreign-employee-headcount-by-nationality/foreign-employee-headcount-by-nationality-chart";
import { AnnualEmployedCompositionByGenderChart } from "../../../classes/stacked-column-line/binary-gender-composition-type/annual-employed-composition-by-gender/annual-employed-composition-by-gender-chart";
import { BaseBinaryGenderCompositionTypeStackedColumnLineData } from "../../../classes/stacked-column-line/binary-gender-composition-type/base-binary-gender-composition-type-stacked-column-line-chart";
import { ExecutiveGenderCompositionChart } from "../../../classes/stacked-column-line/binary-gender-composition-type/executive-gender-composition/executive-gender-composition-chart";
import { BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData } from "../../../classes/stacked-column-line/disabled-employment-headcount-and-rate-type/base-disabled-employment-headcount-and-rate-type-stacked-column-line-chart";
import { DisabledEmploymentHeadcountAndRateChart } from "../../../classes/stacked-column-line/disabled-employment-headcount-and-rate-type/disabled-employment-headcount-and-rate/disabled-employment-headcount-and-rate-chart";
import { AnnualEmployedCompositionByEmploymentTypeChart } from "../../../classes/stacked-column-line/employment-composition-type/annual-employed-composition-by-employment-type/annual-employed-composition-by-employment-type-chart";
import { BaseEmploymentCompositionTypeStackedColumnLineData } from "../../../classes/stacked-column-line/employment-composition-type/base-employment-composition-type-stacked-column-line-chart";
import { BaseGenderCompositionTypeStackedColumnLineData } from "../../../classes/stacked-column-line/gender-composition-type/base-gender-composition-type-stacked-column-line-chart";
import { GenderCompositionChart } from "../../../classes/stacked-column-line/gender-composition-type/gender-composition/gender-composition-chart";
import { BaseGenderHeadcountAndRateTypeStackedColumnLineData } from "../../../classes/stacked-column-line/gender-headcount-and-rate-type/base-gender-headcount-and-rate-type-stacked-column-line-chart";
import { SeparatedHeadcountAndRateByGenderChart } from "../../../classes/stacked-column-line/gender-headcount-and-rate-type/separated-headcount-and-rate-by-gender/separated-headcount-and-rate-by-gender-chart";
import { DashboardBoardEditForm } from "../../../components/dashboard-board-edit-form";
import {
  DashboardChartDisplaySwitch,
  DashboardChartDisplaySwitchModel,
} from "../../../components/dashboard-chart-display-switch";
import { DashboardChartInBoard } from "../../../components/dashboard-chart-in-board";
import { DashboardChartSelect } from "../../../components/dashboard-chart-select";
import { DashboardChartSwitchButton } from "../../../components/dashboard-chart-switch-button";
import { DashboardNoData } from "../../../components/dashboard-no-data";
import { ChartNotesTextDef, ChartUnitCaptionTextDef } from "../../../config/text-def";
import { DashboardChartGetRequest, dashboardApi } from "../../../dashboard-api";
import { filters } from "../../../functions/filters";
import { DashboardStore } from "../../../stores/dashboard-store";
import { BaseData, ChartGroup } from "../../../types";

// GridのPaddingTopの余白分だけ固定位置を調整
const STICKY_CHART_GROUP_GRAPH_TITLE_TOP = -24;
const STICKY_CHART_GROUP_TABLE_TITLE_TOP = -16;
export const DashboardBoardIndex = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams() as { id: string };
  const { isTenantOwner } = AuthStore.useContainer();
  const {
    industryCompaniesCounts,
    allIndustryCompaniesCounts,
    displaySwitchDisabled,
    selectedChartType,
    formDirty,
    setFormDirty,
  } = DashboardStore.useContainer();
  const [groups, setGroups] = useState<ChartGroup[]>([]);
  const [name, setName] = useState("");
  const [loadedCharts, setLoadedCharts] = useState<Chart[]>([]);
  // TODO: フィルタが実装されたら適用ボタンのdisabledを制御する
  const [isAllChartLoaded, setIsAllChartLoaded] = useState<boolean>(false);
  const [filter, setFilter] = useState<DashboardChartGetRequest>();
  const [displaySwitch, setDisplaySwitch] = useState<DashboardChartDisplaySwitchModel>({
    selectedDisplayValues: [],
    showIndustryAverage: false,
    showAllIndustryAverage: false,
    showDataLabel: true,
  });
  const useSettingsQuery = dashboardApi.useSetting();
  const boardQuery = dashboardApi.useBoard(id);
  const useFiltersInBoardQuery = dashboardApi.useFiltersInBoard();
  const updateCustomBoardChartsMutation = dashboardApi.useUpdateCustomBoardCharts(t, enqueueSnackbar, id);
  const [editNameOpen, setEditNameOpen] = useState(false);

  const getSnackbarMessage = () => {
    return (
      <>
        <Typography>{t("snackbar.dashboard.export.submitted")}</Typography>
        <Typography
          component="a"
          href="/"
          ml={2}
          sx={{ color: "common.white", textDecoration: "underline", cursor: "pointer" }}
        >
          {t("snackbar.dashboard.view.export.history")}
        </Typography>
      </>
    );
  };
  const submitExportMutation = dashboardApi.useSubmitExport(t, enqueueSnackbar, getSnackbarMessage());

  useEffect(() => {
    if (useFiltersInBoardQuery.data && useSettingsQuery.data) {
      // 事業年度が登録されていない場合、ルートパスの画面では「事業年度を登録してください」と出て画面から遷移はできないが、
      // 直接URLを入力して遷移した場合は事業年度が登録されていない時に限り、ルートパスにリダイレクトする
      if (useFiltersInBoardQuery.data.businessYears.length > 0) {
        setFilter({
          fromBusinessYearStartDate: filters.getFromBusinessYearStartDate(useFiltersInBoardQuery.data.businessYears),
          toBusinessYearStartDate: useFiltersInBoardQuery.data.businessYears[0].startDate,
          companyType: "original",
          companyIds: [],
          industryDivision: useSettingsQuery.data.industryDivision,
        });
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useFiltersInBoardQuery.data, useSettingsQuery.data]);

  useEffect(() => {
    if (boardQuery.data?.groups) {
      setGroups(boardQuery.data.groups);
    }
  }, [boardQuery.data?.groups]);

  useEffect(() => {
    if (boardQuery.data?.name) {
      setName(boardQuery.data.name);
    }
  }, [boardQuery.data?.name]);

  useEffect(() => {
    if (groups) {
      const charts = groups.flatMap((group) => group.charts);
      setIsAllChartLoaded(loadedCharts.filter((loadedChart) => charts.includes(loadedChart)).length === charts.length);
    }
  }, [groups, loadedCharts]);

  const onClickNameEdit = () => {
    setEditNameOpen(true);
  };

  const onChartLoaded = useCallback((chart: Chart) => {
    setLoadedCharts((prev) => {
      // 既にロードされている場合は更新しない（これがないと無限ループになる）
      if (prev.includes(chart)) return prev;
      return [...prev, chart];
    });
  }, []);

  if (boardQuery.isLoading || useFiltersInBoardQuery.isLoading)
    return (
      <TenantLayout activeKey="dashboard">
        <Grid container>
          <Grid item xs={12}>
            <Loading />
          </Grid>
        </Grid>
      </TenantLayout>
    );
  if (!boardQuery.data || !useFiltersInBoardQuery.data) return null;

  const onSubmitExport = () => {
    if (filter) {
      submitExportMutation.mutate({
        boardId: id,
        charts: boardQuery.data.groups.flatMap((group) => group.charts),
        ...filter,
      });
    }
  };

  const renderChartGroup = (group: ChartGroup) => {
    return (
      <Grid container spacing={selectedChartType === "graph" ? 2 : 1}>
        <Grid
          item
          xs={12}
          mb={selectedChartType === "graph" ? -2 : 0}
          sx={{
            position: "sticky",
            top: selectedChartType === "graph" ? STICKY_CHART_GROUP_GRAPH_TITLE_TOP : STICKY_CHART_GROUP_TABLE_TITLE_TOP,
            zIndex: (theme) => theme.zIndex.appBar,
            bgcolor: (theme) => theme.palette.grey[100],
          }}
        >
          <Typography
            variant="h6"
            pl={1}
            mt={2}
            sx={{
              borderLeft: "4px solid",
              borderColor: "primary.main",
              lineHeight: "initial",
            }}
          >
            {t(GroupTextDef.get(group.group) as string)}
          </Typography>
        </Grid>
        {group.charts.map((chart) => (
          <Grid item xs={12} md={selectedChartType === "graph" ? 6 : 12} key={chart}>
            {renderChart(chart)}
          </Grid>
        ))}
      </Grid>
    );
  };

  const onUpdateCustomBoardCharts = () => {
    setFormDirty(false);
    updateCustomBoardChartsMutation.mutate({
      id: id,
      groups: groups,
    });
  };

  const onUpdateGroups = (groups: ChartGroup[]) => {
    setGroups(groups);
  };

  const renderNameField = (disabled: boolean) => {
    return PRESET_BOARD_IDS.includes(id) ? (
      <Typography variant="h6">{boardQuery.data.name}</Typography>
    ) : (
      <Typography variant="h6">
        {name}
        {disabled ? (
          <EditIcon
            fontSize="small"
            sx={{ ml: 1, mb: -0.25, color: (theme) => theme.palette.grey[500], opacity: DISABLED_OPACITY }}
          />
        ) : (
          <EditIcon
            fontSize="small"
            sx={{
              ml: 1,
              mb: -0.25,
              color: (theme) => theme.palette.grey[500],
              "&:hover": {
                color: (theme) => theme.palette.grey[700],
              },
            }}
            onClick={onClickNameEdit}
          />
        )}
      </Typography>
    );
  };

  const renderChart = (chart: Chart) => {
    const unitCaption = ChartUnitCaptionTextDef.get(chart) && t(ChartUnitCaptionTextDef.get(chart) as string);
    const notes = ChartNotesTextDef.get(chart)?.map((i18nKey) => t(i18nKey));
    const baseChartProps: BaseChartProps = {
      chart,
      unitCaption,
      notes,
    };
    switch (chart) {
      case "gender_pay_gap":
        return (
          <DashboardChartInBoard<BaseEmployeeTypeLineData, GenderPayGapChart>
            chartInstance={GenderPayGapChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "female_manager_ratio":
        return (
          <DashboardChartInBoard<BaseData, FemaleManagerRatioChart>
            chartInstance={FemaleManagerRatioChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "male_childcare_leave_rate":
        return (
          <DashboardChartInBoard<BaseData, MaleChildcareLeaveRateChart>
            chartInstance={MaleChildcareLeaveRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "male_childcare_leave_rate_by_employee_type":
        return (
          <DashboardChartInBoard<BaseEmployeeTypeLineData, MaleChildcareLeaveRateByEmployeeTypeChart>
            chartInstance={MaleChildcareLeaveRateByEmployeeTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "male_childcare_leave_or_childcare_purpose_leave_rate":
        return (
          <DashboardChartInBoard<BaseData, MaleChildcareLeaveOrChildcarePurposeLeaveRateChart>
            chartInstance={MaleChildcareLeaveOrChildcarePurposeLeaveRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "gender_composition_of_all_employee":
      case "gender_composition_of_regular_employee":
      case "gender_composition_of_non_regular_employee":
        return (
          <DashboardChartInBoard<BaseGenderCompositionTypeStackedColumnLineData, GenderCompositionChart>
            chartInstance={GenderCompositionChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "full_time_employee_headcount_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeStackedColumnData, FullTimeEmployeeHeadcountByGenderChart>
            chartInstance={FullTimeEmployeeHeadcountByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "full_time_employee_headcount_by_employee_type":
        return (
          <DashboardChartInBoard<BaseEmployeeTypeStackedColumnData, FullTimeEmployeeHeadcountByEmployeeTypeChart>
            chartInstance={FullTimeEmployeeHeadcountByEmployeeTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "manager_rate":
        return (
          <DashboardChartInBoard<BaseData, ManagerRateChart>
            chartInstance={ManagerRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "span_of_control":
        return (
          <DashboardChartInBoard<BaseData, SpanOfControlChart>
            chartInstance={SpanOfControlChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "province_span_of_control_of_executive":
      case "province_span_of_control_of_executive_officer":
      case "province_span_of_control_of_general_manager":
      case "province_span_of_control_of_division_manager":
      case "province_span_of_control_of_section_manager":
      case "direct_span_of_control_of_executive":
      case "direct_span_of_control_of_executive_officer":
      case "direct_span_of_control_of_general_manager":
      case "direct_span_of_control_of_division_manager":
      case "direct_span_of_control_of_section_manager":
        return (
          <DashboardChartInBoard<BaseHeadcountAndSpanOfControlTypeColumnLineData, ProvinceDirectSpanOfControlChart>
            chartInstance={ProvinceDirectSpanOfControlChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "average_age_of_all_employee_by_gender":
      case "average_age_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeLineData, AverageAgeByGenderChart>
            chartInstance={AverageAgeByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "age_distribution_of_regular_employee_by_gender":
      case "age_distribution_of_manager_by_gender":
        return (
          <DashboardChartInBoard<BaseBinaryGenderTypeColumnData, AgeDistributionByGenderChart>
            chartInstance={AgeDistributionByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "average_service_year_of_all_employee_by_gender":
      case "average_service_year_of_regular_employee_by_gender":
      case "average_service_year_of_non_regular_employee_by_gender":
      case "full_time_employee_average_service_year_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeColumnData, AverageServiceYearByGenderChart>
            chartInstance={AverageServiceYearByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "gender_average_service_year_gap_of_all_employee":
      case "gender_average_service_year_gap_of_regular_employee":
      case "gender_average_service_year_gap_of_non_regular_employee":
      case "full_time_employee_gender_average_service_year_gap":
        return (
          <DashboardChartInBoard<BaseGenderTypeColumnData, GenderAverageServiceYearGapChart>
            chartInstance={GenderAverageServiceYearGapChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "annual_new_graduate_employed_three_year_retention_rate_of_college_graduate_by_gender":
      case "annual_new_graduate_employed_five_year_retention_rate_of_college_graduate_by_gender":
      case "annual_mid_career_employed_three_year_retention_rate_by_gender":
      case "annual_mid_career_employed_five_year_retention_rate_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeLineData, AnnualEmployedXYearRetentionRateByGenderChart>
            chartInstance={new AnnualEmployedXYearRetentionRateByGenderChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "annual_employed_composition_of_all_employee_by_gender":
      case "annual_employed_composition_of_regular_employee_by_gender":
      case "annual_employed_composition_of_non_regular_employee_by_gender":
      case "annual_new_graduate_employed_composition_of_regular_employee_by_gender":
      case "annual_mid_career_employed_composition_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<
            BaseBinaryGenderCompositionTypeStackedColumnLineData,
            AnnualEmployedCompositionByGenderChart
          >
            chartInstance={AnnualEmployedCompositionByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "annual_employed_composition_of_regular_employee_by_employment_type":
        return (
          <DashboardChartInBoard<
            BaseEmploymentCompositionTypeStackedColumnLineData,
            AnnualEmployedCompositionByEmploymentTypeChart
          >
            chartInstance={AnnualEmployedCompositionByEmploymentTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "mid_career_employed_headcount_and_rate_of_all_employee":
      case "mid_career_employed_headcount_and_rate_of_manager":
        return (
          <DashboardChartInBoard<BaseHeadcountAndRateTypeColumnLineData, MidCareerEmployedHeadcountAndRateChart>
            chartInstance={MidCareerEmployedHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "mid_career_employed_executive_headcount_and_rate":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeColumnLineData,
            MidCareerEmployedExecutiveHeadcountAndRateChart
          >
            chartInstance={MidCareerEmployedExecutiveHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "separated_headcount_and_rate_of_regular_employee_by_gender":
      case "personal_reason_separated_headcount_and_rate_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<
            BaseGenderHeadcountAndRateTypeStackedColumnLineData,
            SeparatedHeadcountAndRateByGenderChart
          >
            chartInstance={SeparatedHeadcountAndRateByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "executive_gender_composition":
        return (
          <DashboardChartInBoard<BaseBinaryGenderCompositionTypeStackedColumnLineData, ExecutiveGenderCompositionChart>
            chartInstance={ExecutiveGenderCompositionChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "female_headcount_and_rate_of_division_manager":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineData,
            FemaleHeadcountAndRateOfDivisionManagerChart
          >
            chartInstance={FemaleHeadcountAndRateOfDivisionManagerChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "female_headcount_and_rate_of_section_manager":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineData,
            FemaleHeadcountAndRateOfSectionManagerChart
          >
            chartInstance={FemaleHeadcountAndRateOfSectionManagerChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "female_headcount_and_rate_of_team_leader":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineData,
            FemaleHeadcountAndRateOfTeamLeaderChart
          >
            chartInstance={FemaleHeadcountAndRateOfTeamLeaderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "childcare_leave_rate_of_all_employee_by_gender":
      case "childcare_leave_rate_of_regular_employee_by_gender":
      case "childcare_leave_rate_of_non_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeLineData, ChildcareLeaveRateByGenderChart>
            chartInstance={ChildcareLeaveRateByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "average_childcare_leave_taken_days_of_all_employee_by_gender":
      case "average_childcare_leave_taken_days_of_regular_employee_by_gender":
      case "average_childcare_leave_taken_days_of_non_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<BaseBinaryGenderTypeColumnData, AverageChildcareLeaveTakenDaysByGenderChart>
            chartInstance={AverageChildcareLeaveTakenDaysByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "male_childcare_leave_taken_day_distribution":
        return (
          <DashboardChartInBoard<BaseHeadcountAndRatioTypeColumnLineData, MaleChildcareLeaveTakenDayDistributionChart>
            chartInstance={MaleChildcareLeaveTakenDayDistributionChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "foreign_employee_headcount_by_nationality":
        return (
          <DashboardChartInBoard<BaseNationalityTypeStackedColumnData, ForeignEmployeeHeadcountByNationalityChart>
            chartInstance={ForeignEmployeeHeadcountByNationalityChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "foreign_employee_headcount_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeStackedColumnData, ForeignEmployeeHeadcountByGenderChart>
            chartInstance={ForeignEmployeeHeadcountByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "foreign_employee_headcount_and_rate_of_all_employee":
      case "foreign_employee_headcount_and_rate_of_manager":
        return (
          <DashboardChartInBoard<BaseHeadcountAndRateTypeColumnLineData, ForeignEmployeeHeadcountAndRateChart>
            chartInstance={ForeignEmployeeHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "foreign_new_graduate_employed_headcount_and_rate":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeColumnLineData,
            ForeignNewGraduateEmployedHeadcountAndRateChart
          >
            chartInstance={ForeignNewGraduateEmployedHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "foreign_executive_headcount_and_rate":
        return (
          <DashboardChartInBoard<BaseHeadcountAndRateTypeColumnLineData, ForeignExecutiveHeadcountAndRateChart>
            chartInstance={ForeignExecutiveHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "disabled_employment_headcount_and_rate":
        return (
          <DashboardChartInBoard<
            BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData,
            DisabledEmploymentHeadcountAndRateChart
          >
            chartInstance={DisabledEmploymentHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "disabled_employment_shortage_headcount":
        return (
          <DashboardChartInBoard<BaseData, DisabledEmploymentShortageHeadcountChart>
            chartInstance={DisabledEmploymentShortageHeadcountChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "regular_full_time_employee_average_monthly_hours_actual_working_hours_by_gender":
        return (
          <DashboardChartInBoard<
            BaseGenderTypeLineData,
            RegularFullTimeEmployeeAverageMonthlyHoursActualWorkingHoursByGenderChart
          >
            chartInstance={RegularFullTimeEmployeeAverageMonthlyHoursActualWorkingHoursByGenderChart.getInstance(
              baseChartProps
            )}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "regular_full_time_employee_average_annual_hours_actual_working_hours_by_gender":
        return (
          <DashboardChartInBoard<
            BaseGenderTypeLineData,
            RegularFullTimeEmployeeAverageAnnualHoursActualWorkingHoursByGenderChart
          >
            chartInstance={RegularFullTimeEmployeeAverageAnnualHoursActualWorkingHoursByGenderChart.getInstance(
              baseChartProps
            )}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_all_employee":
      case "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_manager":
      case "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_non_manager":
        return (
          <DashboardChartInBoard<BaseData, RegularEmployeeAverageMonthlyHoursOverScheduledWorkingHoursChart>
            chartInstance={RegularEmployeeAverageMonthlyHoursOverScheduledWorkingHoursChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "regular_employee_average_annual_hours_over_scheduled_working_hours_of_all_employee":
      case "regular_employee_average_annual_hours_over_scheduled_working_hours_of_manager":
      case "regular_employee_average_annual_hours_over_scheduled_working_hours_of_non_manager":
        return (
          <DashboardChartInBoard<BaseData, RegularEmployeeAverageAnnualHoursOverScheduledWorkingHoursChart>
            chartInstance={RegularEmployeeAverageAnnualHoursOverScheduledWorkingHoursChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "average_annual_paid_leave_consumed_days_and_rate_of_all_employee":
      case "average_annual_paid_leave_consumed_days_and_rate_of_regular_employee":
      case "average_annual_paid_leave_consumed_days_and_rate_of_non_regular_employee":
        return (
          <DashboardChartInBoard<BaseDaysAndRateTypeColumnLineData, AverageAnnualPaidLeaveConsumedDaysAndRateChart>
            chartInstance={AverageAnnualPaidLeaveConsumedDaysAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "childcare_purpose_short_time_work_headcount_and_rate_of_all_employee":
      case "childcare_purpose_short_time_work_headcount_and_rate_of_male_employee":
      case "childcare_purpose_short_time_work_headcount_and_rate_of_female_employee":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeColumnLineData,
            ChildcarePurposeShortTimeWorkHeadcountAndRateChart
          >
            chartInstance={ChildcarePurposeShortTimeWorkHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_all_employee":
      case "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_male_employee":
      case "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_female_employee":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeColumnLineData,
            ChildcarePurposeShiftedStartTimeWorkHeadcountAndRateChart
          >
            chartInstance={ChildcarePurposeShiftedStartTimeWorkHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "familycare_leave_headcount_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeStackedColumnData, FamilycareLeaveHeadcountByGenderChart>
            chartInstance={FamilycareLeaveHeadcountByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={() => onChartLoaded(chart)}
          />
        );
      case "average_annual_salary_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeLineData, AverageAnnualSalaryByGenderChart>
            chartInstance={AverageAnnualSalaryByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "average_annual_bonus_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeLineData, AverageAnnualBonusByGenderChart>
            chartInstance={AverageAnnualBonusByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "average_annual_bonus_of_regular_employee_by_grade_type":
        return (
          <DashboardChartInBoard<BaseGradeTypeLineData, AverageAnnualBonusByGradeTypeChart>
            chartInstance={AverageAnnualBonusByGradeTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "salary_increase_rate_of_regular_employee_by_management_type":
        return (
          <DashboardChartInBoard<BaseManagementTypeLineData, SalaryIncreaseRateByManagementTypeChart>
            chartInstance={SalaryIncreaseRateByManagementTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      case "discipline_count_by_discipline_type":
        return (
          <DashboardChartInBoard<BaseDisciplineTypeStackedColumnData, DisciplineCountByDisciplineTypeChart>
            chartInstance={DisciplineCountByDisciplineTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
          />
        );
      default:
        throw new Error(`Invalid chart: ${chart}`);
    }
  };

  return (
    <TenantLayout activeKey="dashboard">
      <Grid container spacing={2}>
        <Grid container item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <IconButton onClick={() => navigate("/")}>
                    <ArrowBackIcon />
                  </IconButton>
                </Grid>
                <Grid item>{renderNameField(!isAllChartLoaded)}</Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container item alignItems="center">
                {id === "mandatory_three_indicators" && (
                  <Grid item>
                    <MyTooltip unauthorized={!isTenantOwner}>
                      <Button
                        disabled={!isAllChartLoaded || submitExportMutation.isLoading || !isTenantOwner}
                        variant="contained"
                        color="normal"
                        onClick={onSubmitExport}
                      >
                        {/* 特別なスタイル調整（Buttonのサイズが大きくならないようにマイナスを使っている */}
                        <DownloadIcon sx={{ ml: -0.6, mr: 0.25, mb: -0.25 }} />
                        <Typography>{t("dashboard.export.csv")}</Typography>
                      </Button>
                    </MyTooltip>
                  </Grid>
                )}
                {!PRESET_BOARD_IDS.includes(id) && (
                  <Grid item>
                    <MyTooltip unauthorized={!isTenantOwner}>
                      <Button
                        size="small"
                        disabled={!isAllChartLoaded || submitExportMutation.isLoading || !isTenantOwner || !formDirty}
                        variant="contained"
                        color="primary"
                        onClick={onUpdateCustomBoardCharts}
                        sx={{ border: "1.5px solid rgba(0, 124, 197, 0)" }}
                      >
                        {t("save")}
                      </Button>
                    </MyTooltip>
                  </Grid>
                )}
                <Grid item ml={2}>
                  <DashboardChartSwitchButton disabled={!isAllChartLoaded} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {!PRESET_BOARD_IDS.includes(id) ? (
                <Grid item xs={12} mb={1}>
                  <Paper sx={{ pt: 2, px: 2, pb: 1 }}>
                    <DashboardChartSelect groups={groups} onUpdateGroups={onUpdateGroups} />
                    <DashboardChartDisplaySwitch
                      model={displaySwitch}
                      updateModel={setDisplaySwitch}
                      industryCompaniesCounts={industryCompaniesCounts}
                      allIndustryCompaniesCounts={allIndustryCompaniesCounts}
                      inBoard
                      disabled={!isAllChartLoaded || displaySwitchDisabled}
                    />
                  </Paper>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <DashboardChartDisplaySwitch
                    model={displaySwitch}
                    updateModel={setDisplaySwitch}
                    industryCompaniesCounts={industryCompaniesCounts}
                    allIndustryCompaniesCounts={allIndustryCompaniesCounts}
                    inBoard
                    disabled={!isAllChartLoaded || displaySwitchDisabled}
                  />
                </Grid>
              )}
              {!PRESET_BOARD_IDS.includes(id) && !groups.length ? (
                <Grid item xs={12}>
                  <Box pt={4}>
                    <DashboardNoData
                      title={t("dashboard.board.chart.no-data")}
                      description={t("dashboard.board.chart.shown-here")}
                    />
                  </Box>
                </Grid>
              ) : (
                groups.map((group) => {
                  return (
                    <Grid item xs={12} key={group.group}>
                      {renderChartGroup(group)}
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <LoadingModal open={submitExportMutation.isLoading || updateCustomBoardChartsMutation.isLoading} />
      {editNameOpen && (
        <DashboardBoardEditForm id={id} name={name} open={editNameOpen} onClose={() => setEditNameOpen(false)} />
      )}
    </TenantLayout>
  );
};
