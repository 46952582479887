import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DialogHeader } from "../../../components/dialog-header";
import { LoadingModal } from "../../../components/loading/loading-modal";
import { MemberQueryResult, memberApi } from "../member-api";

type MemberDeleteDialogProps = {
  target: MemberQueryResult;
  open: boolean;
  onClose: () => void;
};

export const MemberDeleteDialog = ({ target, open, onClose }: MemberDeleteDialogProps) => {
  const { userId, name, email } = target;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const deleteMemberMutation = memberApi.useDeleteMember(t, enqueueSnackbar, userId);

  const onSubmit = () => {
    deleteMemberMutation.mutate();
  };

  useEffect(() => {
    if (deleteMemberMutation.isSuccess) onClose();
  }, [deleteMemberMutation.isSuccess, onClose]);

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogHeader onClose={onClose} dialogType="delete">
          {t("member.delete")}
        </DialogHeader>
        <DialogContent dividers>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography>{t("member.delete.confirmation")}</Typography>
                </Box>
                <Typography>{`${name}（${email}）`}</Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="normal" variant="contained" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button
            disabled={deleteMemberMutation.isLoading}
            variant="contained"
            onClick={onSubmit}
            autoFocus
            color="error"
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingModal open={deleteMemberMutation.isLoading} />
    </>
  );
};
