import Box from "@mui/material/Box";
import React from "react";
import { DashboardIndividualListGetRequest } from "../../dashboard-api";
import { DashboardChartHeader } from "./dashboard-chart-header";

type DashboardChartLayoutProps = {
  children: React.ReactNode;
  individualListFilter: DashboardIndividualListGetRequest;
};

export const DashboardChartLayout = ({ children, individualListFilter }: DashboardChartLayoutProps) => {
  return (
    <Box>
      <DashboardChartHeader individualListFilter={individualListFilter} />
      {children}
    </Box>
  );
};
