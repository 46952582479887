import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CalculateIcon from "@mui/icons-material/Calculate";
import GroupIcon from "@mui/icons-material/Group";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { MoreMenu } from "../../../../components/more-menu";
import { ChartTextDef } from "../../../../config/text-def";
import { Chart } from "../../../../types";
import { NO_FORMULA_CHARTS } from "../../config/const";
import { dashboardApi, DashboardIndividualListGetRequest } from "../../dashboard-api";
import { DashboardStore } from "../../stores/dashboard-store";
import { DashboardChartSwitchButton } from "../dashboard-chart-switch-button";
import { DashboardFormula } from "../dashboard-formula";
import { DashboardIndividualListDialog } from "../dashboard-individual-list-dialog";

export const CHART_HEADER_HEIGHT = 48;

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: `${CHART_HEADER_HEIGHT}px`,
  border: "none",
  borderRadius: 0,
  boxShadow: "none",
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  display: "flex",
  alignItems: "center",
  paddingRight: theme.spacing(1),
}));

type DashboardChartHeaderProps = {
  individualListFilter: DashboardIndividualListGetRequest;
};

export const DashboardChartHeader = ({ individualListFilter }: DashboardChartHeaderProps) => {
  const { t } = useTranslation();
  const { id: chart } = useParams() as { id: Chart };
  const navigate = useNavigate();
  const [formulaOpen, setFormulaOpen] = useState(false);
  const [individualListOpen, setIndividualListOpen] = useState(false);
  const useBusinessYearsQuery = dashboardApi.useBusinessYears();
  const { individualListLegendItems, useIndividualList } = DashboardStore.useContainer();

  const onClickFormula = () => {
    setFormulaOpen(true);
  };

  const onClickIndividualList = () => {
    setIndividualListOpen(true);
  };

  return (
    <StyledPaper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton onClick={() => navigate("/")}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography fontWeight="bold">{t(ChartTextDef.get(chart) as string)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <DashboardChartSwitchButton size="small" />
            </Grid>
            <Grid item sx={{ ml: 1 }}>
              <MoreMenu>
                <MenuItem onClick={onClickFormula} disabled={NO_FORMULA_CHARTS.includes(chart)}>
                  <CalculateIcon
                    sx={{
                      color: (theme) => theme.palette.grey[600],
                      mr: 1,
                    }}
                  />
                  {t("view.formula")}
                </MenuItem>
                {useIndividualList && (
                  <MenuItem onClick={onClickIndividualList}>
                    <GroupIcon
                      sx={{
                        color: (theme) => theme.palette.grey[600],
                        mr: 1,
                      }}
                    />
                    {t("refer.individual-list")}
                  </MenuItem>
                )}
              </MoreMenu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DashboardFormula open={formulaOpen} onClose={() => setFormulaOpen(false)} chart={chart} />
      {individualListOpen && !useBusinessYearsQuery.isLoading && useBusinessYearsQuery.data && (
        <DashboardIndividualListDialog
          open={individualListOpen}
          onClose={() => setIndividualListOpen(false)}
          chart={chart}
          years={useBusinessYearsQuery.data}
          legendItems={individualListLegendItems}
          individualListFilter={individualListFilter}
        />
      )}
    </StyledPaper>
  );
};
