import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import AccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

export interface StyledAccordionSummaryProps extends AccordionSummaryProps {
  my?: number;
}

export const StyledAccordionSummary = styled((props: StyledAccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={props.expandIcon ?? <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme, my }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginTop: my ?? theme.spacing(1.5),
    marginBottom: my ?? theme.spacing(1.5),
    marginLeft: theme.spacing(1),
  },
}));
