import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DialogHeader } from "../../../components/dialog-header";
import { LoadingModal } from "../../../components/loading/loading-modal";
import { WorkspaceQueryResult, workspaceApi } from "../workspace-api";

export type WorkspaceDeleteDialogProps = {
  target: WorkspaceQueryResult;
  open: boolean;
  onClose: () => void;
};

export const WorkspaceDeleteDialog = ({ target, open, onClose }: WorkspaceDeleteDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const deleteWorkspaceMutation = workspaceApi.useDeleteWorkspace(t, enqueueSnackbar, target.id);

  const onSubmit = () => {
    deleteWorkspaceMutation.mutate();
  };

  useEffect(() => {
    if (deleteWorkspaceMutation.isSuccess) onClose();
  }, [deleteWorkspaceMutation.isSuccess, onClose]);

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogHeader onClose={onClose} dialogType="delete">
          {t("workspace.delete")}
        </DialogHeader>
        <DialogContent dividers>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography>{t("workspace.delete.confirmation")}</Typography>
                </Box>
                <Typography>{target.name}</Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="normal" variant="contained" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button
            disabled={deleteWorkspaceMutation.isLoading}
            variant="contained"
            onClick={onSubmit}
            autoFocus
            color="error"
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingModal open={deleteWorkspaceMutation.isLoading} />
    </>
  );
};
