import { BaseData } from "../../../types";
import { BaseChartProps } from "../../base-chart";
import { BaseLineChart } from "../base-line-chart";

export class FemaleManagerRatioChart extends BaseLineChart<BaseData> {
  constructor(props: BaseChartProps) {
    super(props);
    this.useIndividualList = true;
  }
}
