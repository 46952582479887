import DashboardIcon from "@mui/icons-material/Dashboard";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type DashboardNoDataProps = {
  title: string;
  description: string;
};

export const DashboardNoData = ({ title, description }: DashboardNoDataProps) => {
  return (
    <Box alignItems="center" textAlign="center">
      <Box
        mb={1}
        sx={{
          display: "inline-flex",
          bgcolor: "common.white",
          borderRadius: "50%",
          width: 120,
          height: 120,
        }}
      >
        <DashboardIcon
          sx={{
            p: 2,
            color: (theme) => theme.palette.grey[400],
            fontSize: 120,
          }}
        />
      </Box>
      <Typography fontSize={24}>{title}</Typography>
      <Typography variant="body1">{description}</Typography>
    </Box>
  );
};
