import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DialogHeader } from "../../../components/dialog-header";
import { LoadingModal } from "../../../components/loading/loading-modal";
import { BusinessYearQueryResult, businessYearApi } from "../business-year-api";

type BusinessYearDeleteDialogProps = {
  target: BusinessYearQueryResult;
  open: boolean;
  onClose: () => void;
};

export const BusinessYearDeleteDialog = ({ target, open, onClose }: BusinessYearDeleteDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const deleteBusinessYearMutation = businessYearApi.useDeleteBusinessYear(t, enqueueSnackbar, target.startDate);

  const onSubmit = () => {
    deleteBusinessYearMutation.mutate();
  };

  useEffect(() => {
    if (deleteBusinessYearMutation.isSuccess) onClose();
  }, [deleteBusinessYearMutation.isSuccess, onClose]);

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogHeader onClose={onClose} dialogType="delete">
          {t("business-year.delete")}
        </DialogHeader>
        <DialogContent dividers>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography>{t("business-year.delete.confirmation")}</Typography>
                </Box>
                <Typography>{target.name}</Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="normal" variant="contained" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button
            disabled={deleteBusinessYearMutation.isLoading}
            variant="contained"
            onClick={onSubmit}
            autoFocus
            color="error"
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingModal open={deleteBusinessYearMutation.isLoading} />
    </>
  );
};
