import { TFunction } from "i18next";
import { numberUtil } from "../../../../utils/number";
import { DashboardChartDisplaySwitchModel } from "../../components/dashboard-chart-display-switch";
import {
  ALL_INDUSTRY_AVERAGE_COLORS,
  ALL_INDUSTRY_AVERAGE_LINE_WITH_COLUMN_COLORS,
  INDUSTRY_AVERAGE_COLORS,
  INDUSTRY_AVERAGE_LINE_WITH_COLUMN_COLORS,
  OUR_COMPANY_COLORS,
  OUR_COMPANY_LINE_WITH_COLUMN_COLORS,
} from "../../config/const";
import { ChartUnitTextDef } from "../../config/text-def";
import { ChartQueryResult } from "../../dashboard-api";
import { AggregateType, AggregateTypeRow, BaseData, ChartSeriesColumnOption, ChartSeriesLineOption } from "../../types";
import { BaseChart, BaseChartProps } from "../base-chart";

// テンプレートリテラルを利用してBaseChartSeriesとAggregateTypeがイコールにならないようにしている
export type BaseChartSeriesCode = `${AggregateType}`;

type TmpResult<TChartSeriesCode extends string> = {
  code: TChartSeriesCode;
  data: (number | null)[];
  type: "column" | "line";
};

export abstract class BaseColumnLineChart<TData extends BaseData> extends BaseChart<TData> {
  constructor(props: BaseChartProps) {
    super(props);
  }

  getChartOptions(
    t: TFunction,
    queryResult: ChartQueryResult<TData> | ChartQueryResult<TData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    inBoard: boolean
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      return {
        ...super.getChartOptions(t, queryResult, displaySwitch, inBoard),
        yAxis: queryResult.map((queryResult, i) => {
          return {
            title: { text: "" },
            min: 0,
            labels: {
              formatter: function () {
                if (this.value === 0) {
                  return `${this.value} (${t(ChartUnitTextDef.get(queryResult.unit) as string)})`;
                }
                return `${numberUtil.formatWithCommas(this.value as number)}`;
              },
            },
            opposite: i === 1,
          };
        }),
        plotOptions: {
          series: {
            dataLabels: { enabled: displaySwitch.showDataLabel },
          },
          line: {
            zIndex: 2,
          },
          column: {
            zIndex: 1,
          },
        },
        // series: Baseで共通のものができた際に実装
      };
    }
    throw new Error("ChartQueryResult must be array.");
  }

  protected getSeries<TData extends BaseData, TChartSeriesCode extends string>(
    queryResult: ChartQueryResult<TData> | ChartQueryResult<TData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    getChartSeriesCode: (datum: TData) => TChartSeriesCode,
    chartSeriesDefs: [
      Map<TChartSeriesCode, ChartSeriesColumnOption>,
      Map<TChartSeriesCode, ChartSeriesLineOption>,
      TChartSeriesCode[]
    ],
    filterByDisplayValuesFunc?: (data: TData[], displayValues: string[]) => TData[]
  ): (Highcharts.SeriesLineOptions | Highcharts.SeriesColumnOptions)[] {
    if (Array.isArray(queryResult)) {
      const createTmpResults = (
        queryResult: ChartQueryResult<TData>,
        chartSeriesDef: Map<TChartSeriesCode, ChartSeriesColumnOption | ChartSeriesLineOption>,
        type: "column" | "line"
      ) => {
        const tmpResults: TmpResult<TChartSeriesCode>[] = [];
        queryResult.datasets.forEach((dataset) => {
          // dataは空配列などseries分のデータがない場合があるため、seriesでループ
          Array.from(chartSeriesDef.keys()).forEach((code) => {
            // 属すべきseriesを特定
            let tmpResult = tmpResults.find((t) => t.code === code);
            if (!tmpResult) {
              // 存在しないseriesなら新規作成
              tmpResult = { code, data: [], type };
              tmpResults.push(tmpResult);
            }
            const targetData = filterByDisplayValuesFunc
              ? filterByDisplayValuesFunc(dataset.data, displaySwitch.selectedDisplayValues)
              : dataset.data;
            tmpResult.data.push(targetData.find((datum) => getChartSeriesCode(datum) === code)?.value ?? null);
          });
        });
        return this._filterDisplayData(tmpResults, displaySwitch);
      };

      const [columnQueryResult, lineQueryResult] = queryResult;
      const [columnChartSeriesDef, lineChartSeriesDef, orderedSeriesCodes] = chartSeriesDefs;

      return [
        ...createTmpResults(columnQueryResult, columnChartSeriesDef, "column"),
        ...createTmpResults(lineQueryResult, lineChartSeriesDef, "line"),
      ]
        .sort((a, b) => orderedSeriesCodes.indexOf(a.code) - orderedSeriesCodes.indexOf(b.code))
        .map(({ code, data, type }) => {
          if (type === "column") {
            return {
              type: "column",
              name: columnChartSeriesDef.get(code)?.name,
              color: columnChartSeriesDef.get(code)?.color,
              data: data.every((d) => d === null) ? [] : data, // 全ての値がnullの場合はlang.noDataプロパティを利用できるように空配列を渡す
              yAxis: 0,
              showInLegend: code.includes("our_company") || data.some((d) => d !== null),
            };
          } else {
            return {
              type: "line",
              marker: { symbol: "circle" },
              name: lineChartSeriesDef.get(code)?.name,
              color: lineChartSeriesDef.get(code)?.color,
              dashStyle: lineChartSeriesDef.get(code)?.dashStyle,
              data: data.every((d) => d === null) ? [] : data, // 全ての値がnullの場合はlang.noDataプロパティを利用できるように空配列を渡す
              yAxis: 1,
              showInLegend: code.includes("our_company") || data.some((d) => d !== null),
            };
          }
        });
    }
    throw new Error("ChartQueryResult must be array.");
  }

  private _filterDisplayData<TChartSeriesCode extends string>(
    tmpResults: TmpResult<TChartSeriesCode>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): TmpResult<TChartSeriesCode>[] {
    const results = tmpResults.filter((tmpResult) => {
      if (tmpResult.code.startsWith("industry_average")) {
        // aggregateTypeがindustry_averageの場合、displaySwitch.showIndustryAverageがfalseであれば表示対象から除外
        return displaySwitch.showIndustryAverage;
      }
      if (tmpResult.code.startsWith("all_industry_average")) {
        // aggregateTypeがall_industry_averageの場合、displaySwitch.showAllIndustryAverageがfalseであれば表示対象から除外
        return displaySwitch.showAllIndustryAverage;
      }
      return true;
    });
    return results;
  }

  protected getColumnColor(aggregateType: AggregateType, i = 0): string {
    switch (aggregateType) {
      case "our_company":
        return OUR_COMPANY_COLORS[i];
      case "industry_average":
        return INDUSTRY_AVERAGE_COLORS[i];
      case "all_industry_average":
        return ALL_INDUSTRY_AVERAGE_COLORS[i];
    }
  }

  protected getLineColor(aggregateType: AggregateType, i = 0): string {
    switch (aggregateType) {
      case "our_company":
        return OUR_COMPANY_LINE_WITH_COLUMN_COLORS[i];
      case "industry_average":
        return INDUSTRY_AVERAGE_LINE_WITH_COLUMN_COLORS[i];
      case "all_industry_average":
        return ALL_INDUSTRY_AVERAGE_LINE_WITH_COLUMN_COLORS[i];
    }
  }

  protected getDashStyle(aggregateType: AggregateType): Highcharts.DashStyleValue {
    switch (aggregateType) {
      case "our_company":
        return "Solid";
      case "industry_average":
        return "Dot";
      case "all_industry_average":
        return "LongDash";
    }
  }

  // Baseで共通のものができた際にabstractを外して実装
  abstract getAggregateTypeRows(
    t: TFunction,
    queryResult: ChartQueryResult<TData> | ChartQueryResult<TData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[];
}
