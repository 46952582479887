import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { dashboardApi } from "../dashboard-api";
import { DashboardBoardBaseForm, DashboardBoardFormModel } from "./dashboard-board-base-form";

type DashboardBoardEditFormProps = {
  id: string;
  name: string;
  open: boolean;
  onClose: () => void;
};

export const DashboardBoardEditForm = ({ id, name, open, onClose }: DashboardBoardEditFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const updateCustomBoardNameMutation = dashboardApi.useUpdateCustomBoardName(t, enqueueSnackbar, id);

  const onSubmit = ({ name }: DashboardBoardFormModel) => {
    updateCustomBoardNameMutation.mutate({
      id: id,
      name: name,
    });
  };

  return (
    <DashboardBoardBaseForm
      title={t("dashboard.board.name.edit")}
      buttonLabel={t("save")}
      defaultValue={{ name: name }}
      open={open}
      loading={updateCustomBoardNameMutation.isLoading}
      done={updateCustomBoardNameMutation.isSuccess}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
